<template>
  <div class="live_activity_add">
    <div class="outer">
      <h2 class="title">
        {{ $route.query.type == "add" ? "新增直播活动" : "编辑直播活动" }}
      </h2>
      <el-form
        ref="liveForm"
        :model="liveForm"
        label-width="150px"
        :rules="rules"
      >
        <el-form-item
          label="活动名称:"
          prop="title"
        >
          <el-input
            v-model="liveForm.title"
            style="width: 400px"
            placeholder="请输入活动名称"
            size="mini"
            show-word-limit
            maxlength="20"
          />
        </el-form-item>
        <el-form-item
          label="直播初始人数:"
          prop="initialWatch"
        >
          <el-input-number
            v-model="liveForm.initialWatch"
            class=""
            style="width: 400px"
            placeholder="请输入直播初始人数"
            size="mini"
            step-strictly
            :min="0"
            :max="100000"
            :controls="false"
          />
        </el-form-item>

        <el-form-item
          label="直播时间:"
          prop="timeList"
        >
          <el-date-picker
            v-model="liveForm.timeList"
            type="datetimerange"
            range-separator="至"
            size="mini"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
          />
        </el-form-item>
        <el-form-item
          label="选择主播:"
          prop="actorId"
        >
          <el-select
            v-model="liveForm.actorId"
            size="mini"
            placeholder="请选择主播"
            style="width: 400px"
          >
            <el-option
              v-for="item in anchorList"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          label="直播封面图:"
          prop="coverImageId"
        >
          <el-upload
            v-model="liveForm.coverImageId"
            v-loading="loading_cover_image"
            name="multipartFile"
            :class="['avatar-uploader', !liveForm.coverImageId ? 'imgBorder' : '']"
            :action="imgUploadUrl"
            :multiple="false"
            :show-file-list="false"
            :before-upload="(file) => beforeUploadBack(file, 'coverImageId')"
            :on-success="
              (res, file) => handleAvatarSuccess(res, file, 'coverImageId')
            "
          >
            <div
              v-if="liveForm.coverImageId"
              class="avatar"
            >
              <img :src="liveForm.coverImageId" />
            </div>
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            ></i>
          </el-upload>
          <div>
            <a type="primary">图片大小仅限200K以内，建议尺寸300*400</a>
          </div>
        </el-form-item>
        <el-form-item
          label="直播分享图:"
          prop="shareImageId"
        >
          <el-upload
            v-model="liveForm.shareImageId"
            v-loading="loading_share_image"
            name="multipartFile"
            :class="['avatar-uploader', !liveForm.shareImageId ? 'imgBorder' : '']"
            :action="imgUploadUrl"
            :multiple="false"
            :show-file-list="false"
            :before-upload="(file) => beforeUploadBack(file, 'shareImageId')"
            :on-success="
              (res, file) => handleAvatarSuccess(res, file, 'shareImageId')
            "
          >
            <div
              v-if="liveForm.shareImageId"
              class="avatar"
            >
              <img :src="liveForm.shareImageId" />
            </div>
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            ></i>
          </el-upload>
          <div>
            <a type="primary">图片大小仅限200K以内，建议尺寸400*300</a>
          </div>
        </el-form-item>
        <el-form-item
          label="直播背景图:"
          prop="backgroundImageId"
        >
          <el-upload
            v-model="liveForm.backgroundImageId"
            v-loading="loading_background_image"
            name="multipartFile"
            :class="[
              'avatar-uploader',
              !liveForm.backgroundImageId ? 'imgBorder' : ''
            ]"
            :action="imgUploadUrl"
            :multiple="false"
            :show-file-list="false"
            :before-upload="
              (file) => beforeUploadBack(file, 'backgroundImageId')
            "
            :on-success="
              (res, file) => handleAvatarSuccess(res, file, 'backgroundImageId')
            "
          >
            <div
              v-if="liveForm.backgroundImageId"
              class="avatar"
            >
              <img :src="liveForm.backgroundImageId" />
            </div>
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            ></i>
          </el-upload>
          <div>
            <a type="primary">图片大小仅限200K以内，建议尺寸1080*1920</a>
          </div>
        </el-form-item>
        <el-form-item label="标签：">
          <Tag
            v-model="liveForm.tagIdList"
            :tag-scene="10"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            @click="onSure"
          >
            确认
          </el-button>
          <el-button
            plain
            type="danger"
            size="mini"
            @click="onCancel"
          >
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import Tag from './tag.vue';

export default {
  name: 'edit-activity-info',
  components: {
    Tag,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      operateType: '',
      imgUploadUrl: this.$api.nb_common.newUpload,
      liveForm: {
        title: '',
        timeList: [],
        actorId: null,
        coverImageId: '',
        shareImageId: '',
        backgroundImageId: '',
        tokenType: 1,
        tagIdList: [],
        initialWatch: undefined, // 直播初始人数
      },
      actorIdList: [],
      anchorList: [],

      rules: {
        title: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        timeList: [
          { required: true, message: '请选择直播时间', trigger: 'change' },
        ],
        actorId: [
          { required: true, message: '请选择主播', trigger: 'change' },
        ],
        initialWatch: [
          { required: true, message: '请输入直播初始人数', trigger: 'blur' },
        ],
        coverImageId: [
          { required: true, message: '请上传直播封面图', trigger: 'blur' },
        ],
        backgroundImageId: [
          { required: true, message: '请上传直播背景图', trigger: 'blur' },
        ],
        shareImageId: [
          { required: true, message: '请上传直播分享图', trigger: 'blur' },
        ],
      },
      loading_cover_image: false,
      loading_share_image: false,
      loading_background_image: false,

    };
  },
  watch: {

  },
  created() {
    this.operateType = this.$route.query.type;
  },
  mounted() {
    this.getAnchorList();
    if (this.$route.query.type == 'edit' && this.$route.query.id != null) {
      this.getDetail();
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      this.$axios.get(`${this.$api.live.live_events_detail}?id=${this.$route.query.id}`)
        .then((res) => {
          if (res.code === 0) {
            this.liveForm = {
              timeList: [parseInt(res.data.liveStartTime), parseInt(res.data.liveEndTime)],
              title: res.data.title,
              initialWatch: res.data.initialWatch,
              actorId: res.data.actorId,
              coverImageId: res.data.coverImageId,
              shareImageId: res.data.shareImageId,
              backgroundImageId: res.data.backgroundImageId,
              tagIdList: res.data.tagIdList,
              tokenType: 1,
              id: this.$route.query.id,
            };
          }
        });
    },

    // 获取主播列表
    getAnchorList() {
      this.$axios.post(this.$api.live.anchor_list, {
        type: 1,
      }).then((res) => {
        if (res.code === 0) {
          this.anchorList = res.data;
        }
      });
    },

    beforeUploadBack(file, type) {
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png';
      const isLt200 = file.size / 1024 < 200;
      if (type == 'coverImageId') {
        this.loading_cover_image = true;
      } else if (type == 'shareImageId') {
        this.loading_share_image = true;
      } else if (type == 'backgroundImageId') {
        this.loading_background_image = true;
      }
      if (!isJPG && !isPng) {
        this.$message.error('上传图片只能是 JPG 和Png 格式!');
        if (type == 'coverImageId') {
          this.loading_cover_image = false;
        } else if (type == 'shareImageId') {
          this.loading_share_image = false;
        } else if (type == 'backgroundImageId') {
          this.loading_background_image = false;
        }
      }
      if (!isLt200) {
        this.$message.error('上传图片大小不能超过 200kb!');
        if (type == 'coverImageId') {
          this.loading_cover_image = false;
        } else if (type == 'shareImageId') {
          this.loading_share_image = false;
        } else if (type == 'backgroundImageId') {
          this.loading_background_image = false;
        }
      }
      return isLt200 && (isJPG || isPng);
    },
    handleAvatarSuccess(res, file, type) {
      if (res.code === 0) {
        if (type == 'coverImageId') {
          this.liveForm.coverImageId = res.data.fileUrl;
          this.loading_cover_image = false;
        } else if (type == 'shareImageId') {
          this.liveForm.shareImageId = res.data.fileUrl;
          this.loading_share_image = false;
        } else if (type == 'backgroundImageId') {
          this.liveForm.backgroundImageId = res.data.fileUrl;
          this.loading_background_image = false;
        }
      }
    },

    // 取消
    onCancel() {
      this.$confirm('您填写内容将不会被保存', '请确认是否放弃填写', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$router.push('/nb/marketingTools/live');
        })
        .catch(() => {});
    },

    // 确定
    onSure() {
      this.$refs.liveForm.validate((valid) => {
        if (valid) {
          let url = `${this.operateType == 'add' ? this.$api.live.live_events_add : this.$api.live.live_events_edit}`;
          let params = {
            ...this.liveForm,
            liveStartTime: this.liveForm.timeList && this.liveForm.timeList[0] ? this.liveForm.timeList[0] : '',
            liveEndTime: this.liveForm.timeList && this.liveForm.timeList[1] ? this.liveForm.timeList[1] : '',
          };

          this.$axios.post(url, params)
            .then((res) => {
              if (res.code === 0) {
                this.$message({ message: `${this.operateType == 'add' ? '新建成功' : '编辑成功'}`, type: 'success' });
                this.$router.push({
                  path: '/nb/marketingTools/live',

                });
              }
            })
            .catch((err) => {
              this.$message({ type: 'error', message: err });
            });
        } else {
          return false;
        }
      });
    },

  },
};
</script>

<style lang="scss" scoped>
:deep(.el-input__inner) {
  text-align: left;
}
.imgBorder {
  border: 1px dashed #dcdfe6;
}

.avatar-uploader {
  width: 80px;
  height: 80px;
}

.avatar-uploader .el-upload {
  border: 1px solid #dcdfe6;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #dcdfe6;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  overflow: hidden;

  img {
    width: 80px;
    height: 80px;
    display: block;
  }
}
.ad-name {
  margin-left: 200px;
}
</style>
