<template>
  <div class="label-container">
    <div class="label-select-container">
      <div :class="['label-item', props.value.includes(tag.tagId) ? 'selected' : '']" v-for="tag in state.list" @click="selectTag(tag)">
        {{ tag.tagName }}
      </div>
    </div>
  </div>
</template>

<script setup>
  import { reactive, onMounted } from 'vue'
  import axios from '@/http/axios'
  import api from '@/http/api'

  const props = defineProps({
    value: {
      type: Array,
      default() {
        return []
      }
    },
    tagScene: {
      type: Number,
      default: ''
    }
  })

  const state = reactive({
    visible: false,
    list: []
  })

  const emit = defineEmits('input')

  function getList() {
    axios.post(api.Tagcontroller.Tagcontroller_SearchTagList, {
      currentPage: 1,
      pageSize: 100,
      tag_scene: props.tagScene
    }).then(res => {
      const r = res || {}
      if (r.code === 0) {
        state.list = Array.isArray(res.data) ? res.data : []
      }
    })
  }

  function selectTag(tag) {
    let seletTagIds = props.value.concat()
    if (seletTagIds.includes(tag.tagId)) {
      seletTagIds = seletTagIds.filter((id)=> id !== tag.tagId)
    } else {
      seletTagIds.push(tag.tagId)
    }
    emit('input', seletTagIds)
  }

  onMounted(()=>{
    getList()
  })
</script>

<style scoped lang="scss">
@import '@/styles/common';
.label-container {
  @include flex;
  &.center {
    @include flex(null, center);
  }
  .label-select-container {
    @include flex;
    .label-item {
      padding: 0 20px;
      height: 30px;
      cursor: pointer;
      margin-right: 10px;
      margin: 6px 6px 6px 0;
      &.selected {
        @include background(#1890ff);
        @include font(null, #fff);
        @include border(null,null,#1890ff);
      }
      flex-shrink: 0;
      @include flex(null, center, null, nowrap);
      @include border(1px, solid, #eee, 8px);
    }
  }
  .label-item-add {
    &.tag {
      margin-top: 6px;
    }
    margin-left: 20px;
    height: 30px;
  }
}
</style>