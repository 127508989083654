var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "live_activity_add" }, [
    _c(
      "div",
      { staticClass: "outer" },
      [
        _c("h2", { staticClass: "title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$route.query.type == "add" ? "新增直播活动" : "编辑直播活动"
              ) +
              " "
          ),
        ]),
        _c(
          "el-form",
          {
            ref: "liveForm",
            attrs: {
              model: _vm.liveForm,
              "label-width": "150px",
              rules: _vm.rules,
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "活动名称:", prop: "title" } },
              [
                _c("el-input", {
                  staticStyle: { width: "400px" },
                  attrs: {
                    placeholder: "请输入活动名称",
                    size: "mini",
                    "show-word-limit": "",
                    maxlength: "20",
                  },
                  model: {
                    value: _vm.liveForm.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.liveForm, "title", $$v)
                    },
                    expression: "liveForm.title",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "直播初始人数:", prop: "initialWatch" } },
              [
                _c("el-input-number", {
                  staticStyle: { width: "400px" },
                  attrs: {
                    placeholder: "请输入直播初始人数",
                    size: "mini",
                    "step-strictly": "",
                    min: 0,
                    max: 100000,
                    controls: false,
                  },
                  model: {
                    value: _vm.liveForm.initialWatch,
                    callback: function ($$v) {
                      _vm.$set(_vm.liveForm, "initialWatch", $$v)
                    },
                    expression: "liveForm.initialWatch",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "直播时间:", prop: "timeList" } },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "datetimerange",
                    "range-separator": "至",
                    size: "mini",
                    "start-placeholder": "开始时间",
                    "end-placeholder": "结束时间",
                    "value-format": "timestamp",
                    "default-time": ["00:00:00", "23:59:59"],
                  },
                  model: {
                    value: _vm.liveForm.timeList,
                    callback: function ($$v) {
                      _vm.$set(_vm.liveForm, "timeList", $$v)
                    },
                    expression: "liveForm.timeList",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "选择主播:", prop: "actorId" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "400px" },
                    attrs: { size: "mini", placeholder: "请选择主播" },
                    model: {
                      value: _vm.liveForm.actorId,
                      callback: function ($$v) {
                        _vm.$set(_vm.liveForm, "actorId", $$v)
                      },
                      expression: "liveForm.actorId",
                    },
                  },
                  _vm._l(_vm.anchorList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.nickname, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "直播封面图:", prop: "coverImageId" } },
              [
                _c(
                  "el-upload",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading_cover_image,
                        expression: "loading_cover_image",
                      },
                    ],
                    class: [
                      "avatar-uploader",
                      !_vm.liveForm.coverImageId ? "imgBorder" : "",
                    ],
                    attrs: {
                      name: "multipartFile",
                      action: _vm.imgUploadUrl,
                      multiple: false,
                      "show-file-list": false,
                      "before-upload": (file) =>
                        _vm.beforeUploadBack(file, "coverImageId"),
                      "on-success": (res, file) =>
                        _vm.handleAvatarSuccess(res, file, "coverImageId"),
                    },
                    model: {
                      value: _vm.liveForm.coverImageId,
                      callback: function ($$v) {
                        _vm.$set(_vm.liveForm, "coverImageId", $$v)
                      },
                      expression: "liveForm.coverImageId",
                    },
                  },
                  [
                    _vm.liveForm.coverImageId
                      ? _c("div", { staticClass: "avatar" }, [
                          _c("img", {
                            attrs: { src: _vm.liveForm.coverImageId },
                          }),
                        ])
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                  ]
                ),
                _c("div", [
                  _c("a", { attrs: { type: "primary" } }, [
                    _vm._v("图片大小仅限200K以内，建议尺寸300*400"),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "直播分享图:", prop: "shareImageId" } },
              [
                _c(
                  "el-upload",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading_share_image,
                        expression: "loading_share_image",
                      },
                    ],
                    class: [
                      "avatar-uploader",
                      !_vm.liveForm.shareImageId ? "imgBorder" : "",
                    ],
                    attrs: {
                      name: "multipartFile",
                      action: _vm.imgUploadUrl,
                      multiple: false,
                      "show-file-list": false,
                      "before-upload": (file) =>
                        _vm.beforeUploadBack(file, "shareImageId"),
                      "on-success": (res, file) =>
                        _vm.handleAvatarSuccess(res, file, "shareImageId"),
                    },
                    model: {
                      value: _vm.liveForm.shareImageId,
                      callback: function ($$v) {
                        _vm.$set(_vm.liveForm, "shareImageId", $$v)
                      },
                      expression: "liveForm.shareImageId",
                    },
                  },
                  [
                    _vm.liveForm.shareImageId
                      ? _c("div", { staticClass: "avatar" }, [
                          _c("img", {
                            attrs: { src: _vm.liveForm.shareImageId },
                          }),
                        ])
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                  ]
                ),
                _c("div", [
                  _c("a", { attrs: { type: "primary" } }, [
                    _vm._v("图片大小仅限200K以内，建议尺寸400*300"),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "直播背景图:", prop: "backgroundImageId" } },
              [
                _c(
                  "el-upload",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading_background_image,
                        expression: "loading_background_image",
                      },
                    ],
                    class: [
                      "avatar-uploader",
                      !_vm.liveForm.backgroundImageId ? "imgBorder" : "",
                    ],
                    attrs: {
                      name: "multipartFile",
                      action: _vm.imgUploadUrl,
                      multiple: false,
                      "show-file-list": false,
                      "before-upload": (file) =>
                        _vm.beforeUploadBack(file, "backgroundImageId"),
                      "on-success": (res, file) =>
                        _vm.handleAvatarSuccess(res, file, "backgroundImageId"),
                    },
                    model: {
                      value: _vm.liveForm.backgroundImageId,
                      callback: function ($$v) {
                        _vm.$set(_vm.liveForm, "backgroundImageId", $$v)
                      },
                      expression: "liveForm.backgroundImageId",
                    },
                  },
                  [
                    _vm.liveForm.backgroundImageId
                      ? _c("div", { staticClass: "avatar" }, [
                          _c("img", {
                            attrs: { src: _vm.liveForm.backgroundImageId },
                          }),
                        ])
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                  ]
                ),
                _c("div", [
                  _c("a", { attrs: { type: "primary" } }, [
                    _vm._v("图片大小仅限200K以内，建议尺寸1080*1920"),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "标签：" } },
              [
                _c("Tag", {
                  attrs: { "tag-scene": 10 },
                  model: {
                    value: _vm.liveForm.tagIdList,
                    callback: function ($$v) {
                      _vm.$set(_vm.liveForm, "tagIdList", $$v)
                    },
                    expression: "liveForm.tagIdList",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.onSure },
                  },
                  [_vm._v(" 确认 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { plain: "", type: "danger", size: "mini" },
                    on: { click: _vm.onCancel },
                  },
                  [_vm._v(" 取消 ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }