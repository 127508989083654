var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "label-container" }, [
    _c(
      "div",
      { staticClass: "label-select-container" },
      _vm._l(_setup.state.list, function (tag) {
        return _c(
          "div",
          {
            class: [
              "label-item",
              _setup.props.value.includes(tag.tagId) ? "selected" : "",
            ],
            on: {
              click: function ($event) {
                return _setup.selectTag(tag)
              },
            },
          },
          [_vm._v(" " + _vm._s(tag.tagName) + " ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }